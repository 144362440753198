.form {
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    background-color: #A7C8E2;
}

.header>h4 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    opacity: 0.59;
    font-weight: normal;
    font-size: 1.5rem;
}

span.line {
    width: 70px;
    height: 1px;
    background: #A7C8E2;
    display: inline-block;
    margin-right: 15px;
}

.input,
.input:focus,
.input:active {
    font-size: 1rem;
    background-color: transparent !important;
    border: none;
    outline: none;
}

.input::placeholder {
    color: var(--primary-color);
}

.btn {
    margin-left: 15px;
    background: var(--primary-color);
    color: var(--text-color);
    border: 2px solid var(--primary-color);
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

.btn:hover {
    background: var(--text-color);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.title {
    max-width: 500px;
    margin: 0;
}

.formContainer {
    margin-top: 100px;
}

.formHeader {
    font-weight: 600;
    margin: 0 0 10px 0;
}

.contentContainer {
    display: flex;
    justify-content: space-between;
}

.previewContainer {
    display: flex;
    flex: 1;
}

.preview {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.androidBack {
    position: absolute;
    z-index: -1;
}

.android {
    transition: transform 0.25s ease-in-out;
}

.android:hover {
    transform: scale(1.05);
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 25px 0;
}

.error,
.success {
    margin: 10px 0;
    display: block;
}

.success {
    color: #2ed573;
}

.error {
    color: #e84118;
}

@media screen and (max-width: 767px) {

    span.line {
        display: none;
    }
    .contentContainer {
        flex-direction: column-reverse;
    }

    .formHeader {
        text-align: center;
    }
    
    .androidBack img {
        width: 243px;
        height: 425px;
    }

    .android img {
        width: 186px;
        height: 387px;
    }

    .header {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    .header>h4 {
        margin-top: 20px;
    }

    .title {
        margin-top: 10px;
    }

    .form {
        display: flex;
    }

    .btn {
        margin-left: 0;
    }

    .content {
        justify-content: center;
        align-items: center;
    }

    .formContainer {
        margin-top: 50px;
    }
}